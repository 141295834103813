/* eslint-disable no-unreachable */
import Head from "next/head";
import PropTypes from "prop-types";
import React from "react";

import Helpers from "@/utils/helpers/response_handlers";
import { formatDay } from "@/utils/helpers/short_day_to_full_day";

const getFeaturedAmenities = (featuredAmenities) => {
  return featuredAmenities.map((amenity) => {
    const { title } = amenity.fields;

    return `{"@type": "LocationFeatureSpecification ","name": "${title}"}`;
  });
};

export const getOpeningHoursSpecification = (serviceHours) => {
  const sortedHours = {};
  const openingHoursSpecification = [];
  // Convert incoming hours to usable format
  const schedule =
    Helpers.convertFacilityApiLocationHours(serviceHours, "HH:mm") || [];

  // create keys for SortedHours
  schedule.forEach((hours) => {
    const key = `${hours.hours[0].open}-${hours.hours[0].close}`;
    sortedHours[key] = [];
  });

  // sort hours by open and close time on sortedHours object
  schedule.forEach((hours) => {
    const key = `${hours.hours[0].open}-${hours.hours[0].close}`;
    sortedHours[key].push(hours);
  });

  // format sorted sortedHours to schema.org structure
  for (const openingHours in sortedHours) {
    const daysInSet = [];

    sortedHours[openingHours].map((day) => {
      daysInSet.push(`"${formatDay(day.name)}"`);
    });

    // hours are already sorted by matching hours. therefore, taking the first will match for all within a group.
    const { open } = sortedHours[openingHours][0].hours[0];
    const { close } = sortedHours[openingHours][0].hours[0];
    const structuredDay = `{"@type": "OpeningHoursSpecification","dayOfWeek": [${daysInSet}],"opens": "${open}","closes": "${close}"}`;
    openingHoursSpecification.push(structuredDay);
  }

  return openingHoursSpecification;
};

const ClubDetailStructuredData = ({ metaData, facilityData, clubData }) => {
  const {
    name,
    serviceHours,
    contactInformation: {
      address,
      city,
      country,
      state,
      zip,
      phone,
      latitude,
      longitude,
    },
  } = facilityData;
  const { image, description } = metaData;
  const { featuredAmenities, clubDetailPageURL } =
    clubData.fields.clubData.fields;

  const thumbnail = image && image.fields.file.url;
  const amenities = featuredAmenities
    ? getFeaturedAmenities(featuredAmenities)
    : [];
  const openingHours = getOpeningHoursSpecification(serviceHours);

  // stringify then parse data to sanatize data coming from contentful
  const data = JSON.parse(
    JSON.stringify(`{
      "@context": "https://schema.org",
      "@type": "ExerciseGym",
      "image": "${thumbnail}",
      "@id": "https://www.equinox.com${clubDetailPageURL}",
      "name": "${name}",
      "description": "${description}",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "${address}",
          "addressLocality": "${city}",
          "addressRegion": "${state}",
          "postalCode": "${zip}",
          "addressCountry": "${country}"
      },
      "geo": {
          "@type": "GeoCoordinates",
          "latitude": ${latitude},
          "longitude": ${longitude}
      },
      "url": "https://www.equinox.com${clubDetailPageURL}",
      "telephone": "${phone}",
      "currenciesAccepted": "USD",
        "amenityFeature": [${amenities}],
      "openingHoursSpecification": [${openingHours}]
    }`)
  );

  return (
    <Head>
      <script
        // Above Contentful data is stringified then parsed to remove any potential malicious code entered from contentful
        dangerouslySetInnerHTML={{ __html: data }}
        data-type="club-detail-structured-data"
        type="application/ld+json"
      />
    </Head>
  );
};

ClubDetailStructuredData.propTypes = {
  clubData: PropTypes.object,
  facilityData: PropTypes.object,
  metaData: PropTypes.object,
};

export default ClubDetailStructuredData;
