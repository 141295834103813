/* eslint-disable no-unreachable */
module.exports = {
  formatDay: (day) => {
    switch (day) {
      case "mon":
        return "Monday";
        break;
      case "tue":
        return "Tuesday";
        break;
      case "tues":
        return "Tuesday";
        break;
      case "wed":
        return "Wednesday";
        break;
      case "thu":
        return "Thursday";
        break;
      case "fri":
        return "Friday";
        break;
      case "sat":
        return "Saturday";
        break;
      case "sun":
        return "Sunday";
        break;
      default:
        console.error(`Error: ${day} is not a day.`);
    }
  },
};
